<div class="border"></div>
@if (!!label) {
  <div class="label">{{ label }}</div>
}
@if (content) {
  <div class="content">
    <ng-content />
  </div>
}
<div class="border"></div>
