import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'naris-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
  standalone: true
})
export class DividerComponent {
  @Input()
  public label: string;

  @Input()
  set content(val: BooleanInput) {
    this._content = coerceBooleanProperty(val);
  }
  get content() {
    return this._content;
  }

  private _content = false;
}
